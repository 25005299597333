import { Box, Stack, Text, useColorModeValue, Flex, Button, Container, Avatar, HStack, VStack, useDisclosure } from '@chakra-ui/react'
import { Textarea, Heading, } from '@chakra-ui/react'
import { useEffect, useState, useRef } from 'react'


import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import style from './markdown-styles.module.css';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    FormControl,
    FormLabel,
    Input,
    Divider,
    IconButton,
  } from '@chakra-ui/react'

  import {
    FiThumbsUp,
    FiThumbsDown,
  } from 'react-icons/fi'

import { getDatabase, ref, onValue, push, set, off } from "firebase/database";
import axios from 'axios';

export const Main = (props) =>  {
    const { firebaseApp, currentChat, currentMenu, currentUser } = props;

    const db = getDatabase(firebaseApp);

    const [messages, setMessages] = useState({});
    const [newMessage, setNewMessage] = useState("");
    const [newTitle, setNewTitle] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure()
    const initialRef = useRef(null)

    function handleSendMessage(e) {
        const chatMessagesRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/messages/' + currentChat.id);
        const newMessageRef = push(chatMessagesRef);
        set(newMessageRef, {
            content: newMessage,
            timestamp: new Date().getTime(),
            role: "user",
            user: { 
                uid: currentUser.uid,
                name: "Lucas"
            },
            processed: false
        });

        try {
            axios.post('https://api.clipping.ai/tasks/addToQueue/' + currentMenu + '/' + currentUser.uid + '/' + currentChat.id, {
                messages: Object.values(messages),
                newMessage: newMessage
            } ,{
                // headers: { authorization: 'Bearer ' + _token }
                })
            .then(function (response) {
                // handle success
                console.log(response);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
        }
        catch (error) {
            console.log(error);
        }   

        // off(chatMessagesRef);
        off(newMessageRef);
        setNewMessage("");
    }

    let handleInputChange = (e) => {
        let inputValue = e.target.value
        setNewMessage(inputValue)
    }

    let handleTitleChange = (e) => {
        const chatDataRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/chats/' + currentChat.id + '/title');
        set(chatDataRef, newTitle);
        setNewTitle("");
        onClose();
        off(chatDataRef);
    }

    let handleFeedback = (messageId, score) => {
        const messagesRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/messages/' + currentChat.id + '/' + messageId + '/feedback');
        set(messagesRef, score);
        off(messagesRef);

        let feedbackText = null;
        if (score < 0) {
            feedbackText = prompt("Qual foi o problema dessa resposta? Como ela pode ser melhorada?");
        }

        const feedbacksRef = ref(db, 'clippinggpt/feedbacks/private-chats/' + currentMenu);
        const newFeedbackRef = push(feedbacksRef);
        set(newFeedbackRef, {
            score: score,
            timestamp: new Date().getTime(),
            user: currentUser.uid,
            chat: currentChat.id,
            messageId: messageId,
            feedback: feedbackText
        });
        off(feedbacksRef);
        off(newFeedbackRef);
    }

    useEffect(() => {

        if(!currentUser || !currentChat) {
            setMessages({});
            return;
        };

        const chatMessagesRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/messages/' + currentChat.id);
        onValue(chatMessagesRef, (snapshot) => {
            const data = snapshot.val();
            if(snapshot.exists()){
                setMessages(data);
                // console.log("chamou useEffect");
                // console.log(data);
            } else {
                setMessages({});
            }
        });

    },[currentUser, currentChat, db, currentMenu]);

    return (
        <Flex as="main" height="full" direction="column" justify="space-between" {...props}>
            <Box>
                <Stack spacing="8">
                    <Stack spacing="3">
                        <Heading as="h1" size="lg" color={useColorModeValue('gray.700', 'white')} onClick={onOpen}>
                            {currentChat?.title}
                        </Heading>
                        <Text color={useColorModeValue('blackAlpha.600', 'whiteAlpha.600')}>{currentChat?.timestamp}</Text>
                        <Modal
                            initialFocusRef={initialRef}
                            isOpen={isOpen}
                            onClose={onClose}
                        >
                            <ModalOverlay />
                            <ModalContent>
                                <ModalHeader>Dê um título para esta conversa</ModalHeader>
                                <ModalCloseButton />
                                <ModalBody pb={6}>
                                    <FormControl>
                                        <FormLabel>Título</FormLabel>
                                        <Input ref={initialRef} value={newTitle} placeholder='Título' onChange={(e) => setNewTitle(e.target.value)} />
                                    </FormControl>
                                </ModalBody>

                                <ModalFooter>
                                    <Button colorScheme='blue' mr={3} onClick={handleTitleChange}>
                                        Salvar
                                    </Button>
                                    <Button onClick={onClose}>Cancel</Button>
                                </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </Stack>
                    <Stack
                        spacing="5"
                        lineHeight="1.75"
                        // maxW="65ch"
                        color={useColorModeValue('blackAlpha.800', 'whiteAlpha.800')}
                    >
                        {Object.keys(messages).map((messageId) => (
                            <Container maxW="3xl" key={messageId}>
                                <HStack
                                    spacing={4}
                                    alignItems="start"
                                    justifyContent={(messages[messageId].user?.uid === currentUser?.uid) ? 'flex-end' : 'flex-start'}
                                >
                                    {!(messages[messageId].user?.uid === currentUser?.uid) && (
                                            <VStack alignItems='center'>
                                                <Avatar name={messages[messageId].user?.name} />
                                                <IconButton
                                                    variant={messages[messageId].feedback === 1 ? 'primary' : 'ghost'}
                                                    colorScheme='blue'
                                                    aria-label='Thumbs Up'
                                                    fontSize='20px'
                                                    icon={<FiThumbsUp />}
                                                    onClick={() => { handleFeedback(messageId, 1) }}
                                                />
                                                <IconButton
                                                    variant={messages[messageId].feedback === -1 ? 'primary' : 'ghost'}
                                                    colorScheme='teal'
                                                    aria-label='Thumbs Down'
                                                    fontSize='20px'
                                                    icon={<FiThumbsDown />}
                                                    onClick={() => { handleFeedback(messageId, -1) }}
                                                />
                                            </VStack>
                                        
                                        )}
                                    <VStack alignItems={(messages[messageId].user?.uid === currentUser?.uid) ? 'end' : 'start'} spacing={1}>
                                        {/* {!(messages[messageId].user?.uid === currentUser?.uid) && <Text fontWeight="bold">{messages[messageId].user?.name}</Text>} */}
                                        <Box
                                            bg={(messages[messageId].user?.uid === currentUser?.uid) ? 'green.500' : 'blue.500'}
                                            borderRadius="lg"
                                            px={4}
                                            py={2}
                                            color="white"
                                            maxWidth="100%"
                                            wordBreak="break-word"
                                        >
                                            <ReactMarkdown remarkPlugins={[remarkGfm]} className={style.reactMarkDown} fontSize='md'>{messages[messageId].content}</ReactMarkdown>
                                            {(messages[messageId].role !== "user") && (messages[messageId].context) && (
                                                <>
                                                    <HStack>
                                                        <Divider />
                                                        <Text fontSize="lg" fontWeight="medium" whiteSpace="nowrap">
                                                            Referências
                                                        </Text>
                                                        <Divider />
                                                    </HStack>
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} className={style.reactMarkDown} fontSize='md'>{messages[messageId].context}</ReactMarkdown>
                                                </>
                                            )}
                                        </Box>
                                    </VStack>
                                    {(messages[messageId].user?.uid === currentUser?.uid) && <Avatar name={messages[messageId].user?.name} />}
                                </HStack>
                            </Container>
                        ))}
                    </Stack>
                </Stack>
            </Box>
            { currentChat &&
                <Container py={{ base: '4', md: '8' }}>
                    <Box>
                        <HStack
                            spacing={4}
                            alignItems="start"
                            justifyContent="space-between"
                        >
                            <Textarea placeholder='Digite aqui uma pergunta' value={newMessage} onChange={handleInputChange}/>
                            <Button onClick={handleSendMessage} >Submeter</Button>
                        </HStack>
                    </Box>
                </Container>
            }
        </Flex>
    )
}