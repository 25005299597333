import {
    Box,
    Button,
    Flex,
    HStack,
    Icon,
    Link,
    Stack,
    Text,
    useColorModeValue as mode,
  } from '@chakra-ui/react'
  import {
    FiArrowUpRight,
    FiCamera,
    FiHome,
    FiMessageCircle,
    FiX,
    FiClock,
    FiGlobe,
    FiAlertTriangle,
  } from 'react-icons/fi'
  import { ColumnHeader, ColumnIconButton } from './Column'
  
  export const Navbar = (props) => (
    <Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
      <Stack spacing="3">
        <ColumnHeader>
          <HStack spacing="3">
            <ColumnIconButton
              onClick={props.onClose}
              aria-label="Close navigation"
              icon={<FiX />}
              display={{
                base: 'inline-flex',
                lg: 'none',
              }}
            />
            <Text fontWeight="bold" fontSize="sm" lineHeight="1.25rem">
              ClippingGPT | CACD
            </Text>
          </HStack>
        </ColumnHeader>
  
        <Stack px="3" spacing="6">
          <Stack spacing="1">
            <NavLink icon={FiHome} aria-current={props.currentMenu === 'home' ? "page" : ""} onClick={() => {props.setCurrentMenu('home')}}>Home</NavLink>
            {/* <NavLink icon={FiBookOpen} aria-current={props.currentMenu === 'bot-coach' ? "page" : ""} onClick={() => {props.setCurrentMenu('bot-coach')}}>
              Coach de estudos
            </NavLink>
            <NavLink icon={FiCamera}  aria-current={props.currentMenu === 'bot-news' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-news')}}>Atualidades (breve)</NavLink> */}
          </Stack>
          <Stack spacing="3">
            <NavHeading>Tutores</NavHeading>
            <Stack spacing="1">
              {(props.currentUser?.email === 'diogoratm@hotmail.com' || props.currentUser?.email === 'lucarp@lucarp.com' || props.currentUser?.email === 'rafael@clippingcacd.com.br') && (
                <NavLink icon={FiCamera}  aria-current={props.currentMenu === 'bot-diogo' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-diogo')}}>Fichamentos Diogo</NavLink>
              )}
              {(props.currentUser?.email === 'romeu@clippingcacd.com.br' || props.currentUser?.email === 'lucarp@lucarp.com' || props.currentUser?.email === 'rafael@clippingcacd.com.br') && (
                <NavLink icon={FiCamera}  aria-current={props.currentMenu === 'bot-romeu' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-romeu')}}>Fichamentos Romeu</NavLink>
              )}
              <NavLink icon={FiClock}  aria-current={props.currentMenu === 'bot-hb' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-hb')}}>História do Brasil</NavLink>
              <NavLink icon={FiGlobe}  aria-current={props.currentMenu === 'bot-pi' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-pi')}}>Política Internacional</NavLink>
              <NavLink icon={FiMessageCircle}  aria-current={props.currentMenu === 'bot-ing' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-ing')}}>Inglês</NavLink>
              <NavLink icon={FiMessageCircle}  aria-current={props.currentMenu === 'bot-fra' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-fra')}}>Francês</NavLink>
              <NavLink icon={FiMessageCircle}  aria-current={props.currentMenu === 'bot-esp' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-esp')}}>Espanhol</NavLink>
              <NavLink icon={FiAlertTriangle}  aria-current={props.currentMenu === 'bot-hm' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-hm')}}>História Mundial</NavLink>
              <NavLink icon={FiAlertTriangle}  aria-current={props.currentMenu === 'bot-eco' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-eco')}}>Economia</NavLink>
              <NavLink icon={FiAlertTriangle}  aria-current={props.currentMenu === 'bot-geo' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-geo')}}>Geografia</NavLink>
              <NavLink icon={FiAlertTriangle}  aria-current={props.currentMenu === 'bot-dip' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-dip')}}>Direito Internacional</NavLink>
              <NavLink icon={FiAlertTriangle}  aria-current={props.currentMenu === 'bot-dir' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-dir')}}>Direito Interno</NavLink>
            </Stack>
          </Stack>
          {/* <Stack spacing="3">
            <NavHeading>Grupos de estudo</NavHeading>
            <Stack spacing="1">
              <NavLink icon={FiMic} isExternal>
                Galera das antigas
              </NavLink>
              <NavLink icon={FiFigma} isExternal>
                Cursinho
              </NavLink>
              <NavLink icon={FiShield}>Estudantes de Alagoas</NavLink>
              <NavLink icon={FiMessageSquare}>Hacker News</NavLink>
              <NavLink icon={FiFilm}>Movie Guide</NavLink>
            </Stack>
          </Stack>
          <Stack spacing="3">
            <NavHeading>Suporte</NavHeading>
            <Stack spacing="1">
              <NavLink icon={FiTwitter} isExternal  aria-current={props.currentMenu === 'bot-howto' ? "page" : ""}  onClick={() => {props.setCurrentMenu('bot-howto')}}>
                Como usar a ferramenta
              </NavLink>
              <NavLink icon={FiGithub} isExternal>
                Fale conosco
              </NavLink>
            </Stack>
          </Stack> */}
        </Stack>
      </Stack>
      <Box borderTopWidth="1px">
        <NavButton>Logout</NavButton>
      </Box>
    </Flex>
  )
  const NavButton = (props) => (
    <Button
      width="full"
      borderRadius="0"
      variant="ghost"
      size="lg"
      fontSize="sm"
      _hover={{
        bg: mode('gray.100', 'gray.700'),
      }}
      _active={{
        bg: mode('gray.200', 'gray.600'),
      }}
      _focus={{
        boxShadow: 'none',
      }}
      _focusVisible={{
        boxShadow: 'outline',
      }}
      {...props}
    />
  )
  export const NavLink = (props) => {
    const { icon, ...linkProps } = props
    return (
      <Link
        px="2"
        py="1.5"
        borderRadius="md"
        _hover={{
          bg: mode('gray.100', 'gray.700'),
        }}
        _activeLink={{
          bg: 'gray.700',
          color: 'white',
        }}
        {...linkProps}
      >
        <HStack justify="space-between">
          <HStack as="a" spacing="3">
            <Icon as={icon} />
            <Text as="span" fontSize="sm" lineHeight="1.25rem">
              {props.children}
            </Text>
          </HStack>
          {props.isExternal && (
            <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
          )}
        </HStack>
      </Link>
    )
  }
  export const NavHeading = (props) => (
    <Text
      as="h4"
      fontSize="xs"
      fontWeight="semibold"
      px="2"
      lineHeight="1.25"
      color={mode('gray.600', 'gray.400')}
      {...props}
    />
  )