import { Link, Stack, Text, HStack, Box, IconButton, useColorModeValue as mode } from '@chakra-ui/react'

import {
  FiTrash2,
} from 'react-icons/fi'

import { useState, useEffect } from 'react'

import { getDatabase, ref, onValue, remove, off } from "firebase/database";

import moment from 'moment';

export const Sidebar = (props) => {
  const { firebaseApp, currentChat, setCurrentChat, currentMenu, currentUser } = props;
  const db = getDatabase(firebaseApp);

  const [chats, setChats] = useState({});

  useEffect(() => {
    if(!currentUser) return;
    const userContextChatsRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/chats');
    onValue(userContextChatsRef, (snapshot) => {
      const data = snapshot.val();
      if(snapshot.exists()){
          setChats(data);
          setCurrentChat({...data[Object.keys(data)[0]], id: Object.keys(data)[0]});
          // console.log("chamou funcao");
      } else {
        setChats({});
        setCurrentChat(null);
      }
    });

  },[currentUser, currentMenu, db, setCurrentChat]);


  const deleteChat = (chatId, index) => {
    const chatsRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/chats/' + chatId);
    remove(chatsRef);
    off(chatsRef);

    const messagesRef = ref(db, 'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/messages/' + chatId);
    remove(messagesRef);
    off(messagesRef);
    if (chatId === currentChat.id) {
      setCurrentChat(null);
    }
  }

  // function writeUserData() {
    
  //   const newPostRef = push(userContextChatsRef);
  //   set(newPostRef, {
  //       createdAt: new Date().getTime(),
  //       updatedAt: new Date().getTime(),
  //       title: "Untitled chat 1",
  //       lastMessage: "Hello world"
  //   });
  // }

  return (
    <Stack
      spacing={{
        base: '1px',
        lg: '1',
      }}
      px={{
        lg: '3',
      }}
      py="3"
      {...props}
    >
      {Object.entries(chats).map(([chatId, value], index) => (
        <HStack key={chatId}>
          <Link
            aria-current={chatId === currentChat?.id ? 'page' : undefined}
            _hover={{
              textDecoration: 'none',
              bg: mode('gray.100', 'gray.700'),
            }}
            _activeLink={{
              bg: 'gray.700',
              color: 'white',
            }}
            borderRadius={{
              lg: 'lg',
            }}
            flex={1}
            onClick={() => {setCurrentChat({...value, id: chatId} )}}
          >
            <Stack
              spacing="1"
              py={{
                base: '3',
                lg: '2',
              }}
              px={{
                base: '3.5',
                lg: '3',
              }}
              fontSize="sm"
              lineHeight="1.25rem"
            >
              <Text fontWeight="medium">{value.title}</Text>
              <Text opacity={0.8}>{value.lastMessage}</Text>
              <Text opacity={0.6}>{moment(new Date(value.createdAt)).format('DD/MM/YYYY')}</Text>
            </Stack>
          </Link>
          <Box>
            <IconButton variant="ghost" icon={<FiTrash2 />} onClick={() => deleteChat(chatId, index)}/>
          </Box>
        </HStack>
      ))}
    </Stack>
  )

}