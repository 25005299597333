// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from './theme'
import { useEffect } from 'react'

import axios from 'axios';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getDatabase, ref, set, push } from "firebase/database";


import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  useColorModeValue,
  useDisclosure,
  Container,
  Stack,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { FiArrowLeft, FiHelpCircle, FiMenu, FiRss } from 'react-icons/fi'
import { ColumnButton, ColumnHeader, ColumnHeading, ColumnIconButton } from './components/Column'
import { Main } from './components/Main'
import { Navbar } from './components/Navigation'
import { Sidebar } from './components/Sidebar'

var config = {
  apiKey: "AIzaSyCKA8NpVeKchDIXXYIqp7lbemI8yqLgieo",
  authDomain: "clipping-api.firebaseapp.com",
  databaseURL: "https://clipping-api.firebaseio.com",
  projectId: "clipping-api",
  storageBucket: "clipping-api.appspot.com",
  messagingSenderId: "926210556621",
  appId: "1:926210556621:web:6c52e998ad6ce029d1df84",
  measurementId: "G-0DRTYCNECD"
};

const app = initializeApp(config);
const analytics = getAnalytics(app);

function App(){
  const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false)
  const [mainIsScrolled, setmMainIsScrolled] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [currentChat, setCurrentChat] = useState(null);
  const [currentMenu, setCurrentMenu] = useState('home');

  // FIREBASE
  const auth = getAuth();
  const database = getDatabase(app);

  //// LOGIN
  useEffect(() => {
    setIsLoggingIn(true);
    let _token = window.location.search.replace('?token=', '');
    if (_token) {
      sessionStorage.setItem('token', _token);
      window.location.href="/";
    }

    _token = sessionStorage.getItem('token', _token);
    if (_token) {
      axios.get('https://api.clippingconcursos.com.br/login/firebase_token', {
          headers: { authorization: 'Bearer ' + _token }
        })
      .then(function (response) {
        signInWithCustomToken(auth, response.data.firebaseToken).then(function () {	
          const user = getAuth().currentUser;
          setCurrentUser(user);
        }).catch(function (err) {
            console.log(err)    
            alert("Você precisa logar novamente!");
            setCurrentUser(null);
            window.location.href="https://clippingconcursos.com.br/acessar";
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        setCurrentUser(null);
        setIsLoggingIn(false);
      });
    }
    else {
      if(getAuth().currentUser){
        setCurrentUser(getAuth().currentUser);
        setIsLoggingIn(false);
      } 
      else {
        window.location.href="https://clippingconcursos.com.br/acessar";
      }
    }
  }, [auth]);

  function startNewChat() {
    const chatRef = ref(database,'clippinggpt/private-chats/' + currentMenu + '/' + currentUser.uid + '/chats');
    const newChatRef = push(chatRef);
    const newChat = {
      id: newChatRef.key,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      createdBy: currentUser.uid,
      title: 'Untitled Chat',
      lastMessage: "",
    };
    set(newChatRef, newChat);
    setCurrentChat(newChat);
  }

  return (
    <ChakraProvider theme={theme}>
      { isLoggingIn && (
        <Box as="section" pb={{ base: '12', md: '24' }}>
          <Box bg="bg-accent" color="on-accent" position="relative">
            <Container py={{ base: '4', md: '3.5' }}>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                justify="center"
                spacing={{ base: '0.5', md: '1.5' }}
                pe={{ base: '4', sm: '0' }}
              >
                <Text fontWeight="medium">Aguarde enquanto buscamos seus dados....</Text>
              </Stack>
              {/* <CloseButton position="absolute" right="2" top="2.5" /> */}
            </Container>
          </Box>
        </Box>
      ) }
      <Flex height="100vh">
        <Box
          height="full"
          width={{ md: '14rem', xl: '18rem' }}
          display={{ base: 'none', lg: 'initial' }}
          overflowY="auto"
          borderRightWidth="1px"
        >
          <Navbar currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} currentUser={currentUser}/>
        </Box>
        <Box
          borderRightWidth="1px"
          width={{ md: '20rem', xl: '24rem' }}
          display={{ base: 'none', md: 'initial' }}
          overflowY="auto"
          onScroll={(x) => setSidebarIsScrolled(x.currentTarget.scrollTop > 32)}
        >
          <ColumnHeader shadow={sidebarIsScrolled ? 'base' : 'none'}>
            <HStack justify="space-between" width="full">
              <HStack spacing="3">
                <ColumnIconButton
                  onClick={onOpen}
                  aria-label="Open Navigation"
                  icon={<FiMenu />}
                  display={{ md: 'inline-flex', lg: 'none' }}
                />
                <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
                  <DrawerOverlay />
                  <DrawerContent>
                    <Navbar onClose={onClose} />
                  </DrawerContent>
                </Drawer>
                <ColumnHeading>Minhas conversas</ColumnHeading>
              </HStack>
              { currentUser && currentMenu && (<ColumnButton leftIcon={<FiRss />} onClick={() => startNewChat()}>Novo chat</ColumnButton>) }
            </HStack>
          </ColumnHeader>
          <Sidebar currentMenu={currentMenu} currentChat={currentChat} setCurrentChat={setCurrentChat} firebaseApp={app} currentUser={currentUser}/>
        </Box>
        <Box
          bg={useColorModeValue('white', 'gray.900')}
          flex="1"
          overflowY="auto"
          onScroll={(x) => setmMainIsScrolled(x.currentTarget.scrollTop > 32)}
        >
          <ColumnHeader shadow={mainIsScrolled ? 'base' : 'none'}>
            <HStack justify="space-between" width="full">
              <HStack spacing="3">
                <ColumnIconButton
                  aria-label="Navigate back"
                  icon={<FiArrowLeft />}
                  display={{ base: 'inline-flex', md: 'none' }}
                />
                {mainIsScrolled && <ColumnHeading>{currentChat?.title}</ColumnHeading>}
              </HStack>
              <ColumnButton leftIcon={<FiHelpCircle />} onClick={ () => { window.open('https://ajuda.clippingcacd.com.br/pt-BR/articles/7925759-como-usar-o-clippinggpt', '_blank'); }}>Ajuda</ColumnButton>
            </HStack>
          </ColumnHeader>
          <Main currentMenu={currentMenu} currentChat={currentChat} firebaseApp={app} currentUser={currentUser} maxW="3xl" mx="auto" py="8" px={{ base: '4', md: '8' }} />
        </Box>
      </Flex>
    </ChakraProvider>
  )
}

export default App;